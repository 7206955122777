/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import SEO from '../components/SEO/SEO'
import { graphql } from 'gatsby'
import MediaRoll from '../components/MediaRoll'
class MediaTemplatePage extends React.Component {
    render() {
        const data = this.props.data
        const location = this.props.location
        const jsonData = data.allArticlesJson.edges[0].node.articles
        return (
            <Layout data={data} jsonData={jsonData} location={location}>
                <SEO frontmatter={data.markdownRemark.frontmatter} />
                <div className="container">
                    <section className="is-blog-section">
                        <div className="container">
                            <MediaRoll />
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

MediaTemplatePage.propTypes = {
    data: PropTypes.object,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default MediaTemplatePage

export const pageQuery = graphql`
    query MediaTemplate($id: String!) {
        site {
            siteMetadata {
                title
                languages {
                    langs
                    defaultLangKey
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                id
                date
                title
                description
                tags
                lang
            }
        }
    }
`
